import BaseModel from "@/models/BaseModel";
import { CustomerAddress } from "@/models/CustomerAddress";
import { toSnake } from "@/helpers/caseConverter";
import Employee from "@/models/Employee";
import Company from "@/models/Company";


export default class Customer extends BaseModel {
  static entity = "customers";
  static modelPath = "customers";
  static formKey = "customer";

  get isLoggedIn() {
    const loggableAccountTypes = ["shop", "facebook", "google"];
    return loggableAccountTypes.includes(this.account_type);
  }

  get noRegistrationAccount() {
    return this.account_type === "no_registration";
  }

  get isGuest() {
    return this.account_type === "guest";
  }

  get isRegistered() {
    return this.isLoggedIn;
  }

  get canPlaceOrder() {
    const ordelableAccountTypes = [
      "no_registration",
      "shop",
      "facebook",
      "google"
    ];
    return ordelableAccountTypes.includes(this.account_type);
  }

  static fields() {
    return {
      id: this.uid(),
      name: this.string("").nullable(),
      last_name: this.string("").nullable(),
      email: this.string("").nullable(),
      account_type: this.string(""),
      is_active: this.boolean(false),
      regulation_acceptance: this.boolean(false),
      privacy_acceptance: this.boolean(false),
      marketing_acceptance: this.boolean(false),
      employee_id: this.number(null).nullable(),
      company_id: this.number(null).nullable(),
      customer_addresses: this.hasMany(CustomerAddress, "customer_id"),
      created_at: this.attr(),
      updated_at: this.attr(),
      employee: this.belongsTo(Employee, "employee_id"),
      company: this.belongsTo(Company, "company_id")
    };
  }

  static fetchActive() {
    return this.api()
      .get("customer/active")
      .then(({ response }) => {
        // remove old data
        const activeUserId =
          response && response?.data && response.data?.id
            ? response.data.id
            : false;
        if (activeUserId) {
          this.store().dispatch("shopAuth/setActiveCustomer", activeUserId);
          Customer.delete((customer) => customer.id !== activeUserId);
          CustomerAddress.delete(
            (customerAddress) => customerAddress.customer_id !== activeUserId
          );
        }
      });
  }

  static setField(fieldName, value) {
    const data = {};
    data[this.formKey] = {};
    data[this.formKey].field_name = toSnake(fieldName);
    data[this.formKey][toSnake(fieldName)] = value;
    return this.api().put("customer/active/update_field", data);
  }

  static authCustomerWithSession(payload) {
    const data = {
      session: {
        email: payload.email,
        password: payload.password,
        mode: "customer",
        method: "header"
      }
    };
    return this.api().post("session/auth_customer", data);
  }

  static uploadPriceList(shopId, customerId, form, progressHandler) {
    const formData = this.createMultipartForm(form);
    const axiosConfig = this.createMultipartFormAxiosConfig(progressHandler);
    return this.api().post(
      `/shops/${shopId}/customers/${customerId}/upload_price_list`,
      formData,
      axiosConfig
    );
  }
}
